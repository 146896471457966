/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";

export default function NEWANIMODELModel(props) {
  const { nodes, materials } = useGLTF("/animalfaceNEWPLEASE.glb");

  const deg2rad = degrees => degrees * (Math.PI / 180);

  const ref = useRef();
  const { viewport } = useThree();

  useFrame(({ mouse }) => {
    const x = (mouse.x * viewport.width) / 10;
    const y = (mouse.y * viewport.height) / 6;
    ref.current.position.set(0, 0, -5);
    ref.current.rotation.set((deg2rad(90) - y), y / -2, -x);
  });

  return (
    <>
      <group {...props} dispose={null}>
        <mesh
          geometry={nodes.Cube001.geometry}
          material={materials.Material}
          position={[0, 0, 0]}
          rotation={[0, 0, 0]}
          ref={ref}
        />
      </group>
      <ambientLight intensity={1} />
      <pointLight color="white" position={[2, 0, 5]} intensity={1.2} />
    </>
  );
}

useGLTF.preload("/animalfaceNEWPLEASE.glb");
